import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {mqMax} from '@facepaint';

export const CompetenceWrapper = styled.div`
    background-color: white;
    width: 100%;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
    padding: 20px;
    max-width: 1280px;
  height: 100%;
  overflow-y: auto;
    margin: 0 auto;
  padding-bottom: 100px;
  margin-top: 50px;
  .header {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  position: relative;
`;

export const SubHeader = styled.div`
    background-color: white;
    width: 100%;
    cursor: pointer;
    padding-bottom: 20px;
    padding-top: 10px;
    margin-top: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;


    .ico-back {
      position: absolute;
      color: #6B7280;
      font-size: 36px;
      top: -10px;
      cursor: pointer;
      left: 30px;
    }
`;

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex: 1 1 auto;
  top: 0;
  bottom: 0;
  z-index: 2002;
  background-color: rgba(0,0,0,.5);
  width: 100%;
  flex-direction: column;
  .close-btn{
    top: 1.75em;
    right: 1.75em;
  }
  ${mqMax.medium}{
    .close-btn{
        top: 1em;
        right: 1em;
    }
  }
`;
