import {ArrowDown} from 'phosphor-react';
import React, {useState} from 'react';
import {
  TableReport,
  Header,
  NormalHeader,
  NormalHeaderContent,
  TableReportWrapper,
} from '@routes/employees/components/employees-report/employees-report-styles';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import Progress from '@routes/employees/components/employees-report/components/progress';
import {i18n} from '@src/i18n';

const ReportCompetence = ({mainOrganisation, report}) => {
  const [sortOrder, setSortOrder] = useState('asc');

  return (
    <TableReportWrapper>
      <TableReport stickyFirstCol>
        <tr>
          <Header
            css={css`
              vertical-align: bottom;
              padding-bottom: 2.5em;
              min-width: 17em;
          `}
          >
            <div css={css`display: flex;`}>
              {i18n('globals.name')}
              <SortArrow
                css={css`
                                margin-left: auto;
                                margin-right: 1em;
                            `}
                sortOrder={sortOrder}
                onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                newDesc={sortOrder === 'desc' ? 'A til Å' : 'Å til A'}
              />
            </div>
          </Header>
          {report.data.x.map(data => (
            <NormalHeader nowrap>
              <NormalHeaderContent>
                {data.title}
              </NormalHeaderContent>
            </NormalHeader>
          ))}
        </tr>
        {
        report.data.y.sort((a, b) => sortOrder === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)).
          map(data => (
            <tr>
              <td>
                {data.name}
              </td>
              {report.data.x.map(course => (
                <td>
                  {Object.keys(data.data).includes(course.id)
                  && <Progress data={data.data[course.id]} />
                 || (
                   <>
                    &nbsp;
                   </>
                 )}
                </td>
              ))}
            </tr>
          ))
}
      </TableReport>
    </TableReportWrapper>
  );
};

const SortArrow = ({onClick, sortKey, newDesc, sortOrder, ...props}) => (
  <SortArrowWrapper
    {...props}
    rotation={sortOrder === 'asc' ? '0deg' : '180deg'}
  >
    <ArrowDown
      role="button"
      tabIndex="0"
      aria-label={`Endre sortering av ${sortKey} til ${newDesc}`}
      onClick={onClick}
    />
  </SortArrowWrapper>
);


const SortArrowWrapper = styled.div`
  transform: rotate(${props => props.rotation});
  transform-origin: center center;
  &:hover{
    cursor: pointer;
  }
`;

export default ReportCompetence;
