import React, {Component} from 'react';

import {withRouter} from 'react-router';
import * as CT from '../../../store/types/competence';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getActiveCourse} from '@selectors/courses.selectors';
import {getCompetences, getPassed, getPassedCompetences, getPassedIds} from '@selectors/profile.selectors';

import {Redirect} from 'react-router-dom';
import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import {getRouteWithParams} from '@utils/routes.utils';
import {bindActionCreators} from 'redux';

import {
  coursesCourseFinished,
  coursesSignCourse,
  coursesStartCourse,
  coursesBeginSignature,
} from '@actions/courses.actions';
import {cheatCompetence} from '@actions/profile.actions';

import {i18n, i18nFormatDate} from '@src/i18n';
import {ActionButton} from '@components/buttons/actionButton';
import {css} from '@emotion/react';
import {Pencil} from 'phosphor-react';

class CourseButtons extends Component {
  constructor(props) {
    super(props);
    this.onStartCourseClick = this.onStartCourseClick.bind(this);
    this.onEndCourseClick = this.onEndCourseClick.bind(this);
    this.onCheatClick = this.onCheatClick.bind(this);
  }

  state = {action: undefined};

  onEndCourseClick() {
    const {coursesCourseFinished, activeCourse} = this.props;

    coursesCourseFinished({cid: activeCourse.cid});
  }

  onCheatClick(id) {
    const {cheatCompetence} = this.props;

    cheatCompetence({id});
  }

  onStartCourseClick(cid, type) {
    const {coursesStartCourse} = this.props;

    coursesStartCourse({
      cid,
      type,
    });
  }

  render() {
    const {
      course,
      passedCoursesIds,
      activeCourse,
      disabled,
      coursesBeginSignature,
      passedCompetences,
    } = this.props;
    const {action} = this.state;

    const isDone = passedCoursesIds.includes(Number.parseInt(course.id, 0));
    const personCompetence = passedCompetences.data && passedCompetences.data.find(pc => pc.competence_id === course.competence_id);
    const courseType = Object.keys(course).includes('competence_type_key') && course.competence_type_key || course.competence_type.competence_type;
    return (
      <>
        {action && (
          <Redirect
            to={{
              pathname: getRouteWithParams(myEducationRoutes.courseView.path, {courseId: course.id}),
              state: {action},
            }}
          />
        )}
        {CT.CourseTypes.digitalsignature.includes(courseType)
          && (isDone && (
            <div className="course-preview__actions sign-button-wrapper">
              <p css={
                css`
                    color: #444444;
                    font-size: 0.65rem;
                `}
              >
                {i18n('competence.signed-at-x', {
                  functionArgs: {x: i18nFormatDate(personCompetence.date)}}
                )}
              </p>
            </div>
          ) || (
            <div className="course-preview__actions sign-button-wrapper">
              <ActionButton
                type="button"
                variant={disabled ? 'disabled'  : 'primary'}
                withPhosphorIcon={{
                  icon: Pencil,
                  weight:'fill',
                  fontSize: '15px',
                }}
                disabled={disabled}
                onClick={() => {
                  coursesBeginSignature(course);
                }}
              >
                {i18n('competence.sign')}
              </ActionButton>
            </div>
          ))}

        {Boolean(CT.CourseTypes.ecourse.includes(courseType)
          || CT.CourseTypes.nano.includes(courseType)) && (
            <div className="course-preview__actions">
              {false && (
                <button
                  type="button"
                  className="btn course-preview__action-button"
                  onClick={() => {
                    this.onCheatClick(course.id);
                  }}
                >
                  CHEAT
                </button>
              )}

              {activeCourse
              && activeCourse.cid === course.competence_id
              && activeCourse.status === 0 && (
                <button
                  type="button"
                  className="btn course-preview__action-button"
                >
                  ....
                </button>
              ) || (
                <ActionButton
                  type="button"
                  variant="primaryTheme"
                  withRightChrevron
                  css={{paddingLeft:'1.3em'}}
                  onClick={() => {
                    this.onStartCourseClick(
                      course.id,
                      courseType,
                    );
                  }}
                >
                  {isDone && i18n('course.restart') || i18n('course.start')}
                </ActionButton>
              )}
            </div>
        )}
      </>
    );
  }
}

CourseButtons.propTypes = {
  course: PropTypes.shape({}),
  activeCourse: PropTypes.shape({}),
  passedCoursesIds: PropTypes.shape({}).isRequired,
  coursesCourseFinished: PropTypes.func.isRequired,
  cheatCompetence: PropTypes.func.isRequired,
  coursesStartCourse: PropTypes.func.isRequired,
  coursesBeginSignature: PropTypes.func.isRequired,
};

CourseButtons.defaultProps = {
  course: null,
  activeCourse: null,
};

const mapStateToProps = state => ({
  passedCoursesIds: getPassedIds(state),
  activeCourse: getActiveCourse(state),
  signedCourse: state.courses.courseSignCourse,
  passedCompetences: getPassed(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      coursesStartCourse,
      coursesSignCourse,
      coursesCourseFinished,
      cheatCompetence,
      coursesBeginSignature,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseButtons));
