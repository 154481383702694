import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';

export const TableReportWrapper = styled.div`
  width: 100%;
  clear: both;
  padding-bottom: 30px;
  overflow: auto;
`;

export const TableReport = styled.table`
    td, th{
        padding: 0.7em;
    }

  border-spacing: 0;

  ${({stickyFirstCol}) => stickyFirstCol && css`
    tr td:first-child, tr th:first-child {
      position: sticky;
      left: 0;
      background: white;
      z-index: 2;
    }
  `}
`;

const colors = {
  red:'#F03E3E',
  green:'#008243',
  header:'#3B4D63',
  gray:'#E0E0E0',
};

export const Dot = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 1em;
  width: 1em;
  ${({fill}) =>
  ({
    green: css`
        background-color: ${colors.green};
      `,
    red: css`
        background-color: ${colors.red};
      `,
    gray: css`
        background-color: ${colors.gray};
      `,
  }[fill])};
`;

const cellStyle = css`
    white-space: nowrap;
`;

export const Cell = styled.td`
    ${cellStyle};
`;

export const ReportUL = styled.ul`
    margin: 0;
    padding: 0
`;

export const ReportLI = styled.li`
    list-style: none;
    float: left;
    padding-right: 40px;
    ${({label}) => css`
        cursor: ${label ? 'default':'pointer'};
        font-weight: ${label ? '600' : 'normal'};
    `}
    color: ${({theme, active}) => active ? theme.colors.reports.selectReport.colorSelected : theme.colors.reports.selectReport.color};
`;

export const ProgressText = styled.div`
    position: absolute;
    width: 100%;
    left: 25%;
`;

export const ProgressWrapper = styled.div`
    position: relative;
    font-size: 0.7em;
    height: 18px;
`;
export const ProgressBG = styled.div`
    background-color: #EDEDED;
    position: relative;
    font-size: 0.7em;
    width: 50%;
    text-align: center;
    border-radius: 3px;
    height: 18px;
`;

export const ProgressLine = styled.div`
    background-color: ${({theme}) => theme.colors.reports.progressBar.backgroundColor};
    color: white;

    overflow: hidden;
    position: absolute;
    border-radius: 3px;
    width: ${props => props.value ? props.value + '%' : '0%'};
    height: 18px;
`;

export const Header = styled.th`
    ${cellStyle};
    color: ${colors.header};
    font-weight: 500;
    text-align: left;
    font-size: 0.7em;
`;

export const NormalHeader = styled(Header)`
    ${cellStyle};
    transform: rotate(-55deg);
    height: 205px;
    transform-origin: 95px 122px;
`;

export const NormalHeaderContent = styled.div`
    width: 7.5em;
`;

export const RotatingHeader = styled(Header)`
    ${cellStyle};
    transform: rotate(-55deg);
    height: 315px;
    transform-origin: 155px 222px;
`;

export const RotatingHeaderContent = styled.div`
    width: 3.5em;
`;
