import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './competence.scss';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {getProfile} from '@selectors/profile.selectors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';
import completed from '../../../assets/svg/completed.svg';

import {
  fetchCompetencesChildren,
  profileToggleCompetence,
} from '@actions/profile.actions';
import CompetencesList from '@components/competences/competence-list';
import {
  faCalendarAlt,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faUserCheck,
} from '@fortawesome/pro-solid-svg-icons';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import {ActionButton} from '@components/buttons/actionButton';
import {getIsMobile} from '@selectors/global.selectors';
import {Pencil} from 'phosphor-react';
import {AnimatePresence, motion} from 'framer-motion';
import {css} from '@emotion/react';
import {FilesList} from '@components/files-list/files-list';

const createSubtitleContent = content => ({__html: content});

class CompetenceEquivalents extends Component {
  state = {isExpanded: false};

  handleChildrenUpdate = values => {
    const {course, root, section, showChildren} = this.props;
    const {isExpanded} = this.state;

    if (showChildren) {
      showChildren(values);
    }

    this.setState({isExpanded: !isExpanded});
    if (!isExpanded && course.children  && course.children.length === 0) {
      const {fetchCompetencesChildren} = this.props;

      fetchCompetencesChildren({
        courseId: values,
        root,
        isOtherCompetences: section === 'other',
      });
    }
  }

  removePersonalCompetence(id) {
    const {removeRequirement} = this.props;

    removeRequirement(id);
  }

  render() {
    const {
      size,
      course,
      roleName,
      isFetching,
      sub,
      canEdit,
      onEdit,
      defaultImg,
      theme,
      profileToggleCompetence,
      isMobile,
      noExpandChildren,
    } = this.props;
    const {isExpanded} = this.state;

    return (
      <>
        {size === 'compact' && (
          <div className={`competence-card ${size}`}>
            <div
              className="grid-x clickable-card"
              role="button"
              tabIndex={0}
              onKeyPress={() => this.handleChildrenUpdate(course.competence_id)}
              onClick={() => this.handleChildrenUpdate(course.competence_id)}
            >
              <div className="cell auto">
                <div className="small-header">
                  {i18nCourseGetCompetenceType(course)}
                  {roleName && (
                    <span>
                      {' '}
                      (
                      {roleName}
                      )
                    </span>
                  )}
                </div>
                {course.competence_title}
              </div>

              <div className="cell shrink button">
                {!isExpanded && <FontAwesomeIcon icon={faChevronDown} /> || (
                  course.children
                    && course.children.length === 0
                    && isFetching && <span>...</span>
                    || <FontAwesomeIcon icon={faChevronUp} />
                )}
              </div>
            </div>

            <div className="small-12 cell">
              <AnimatePresence>
                {isExpanded && course.children && course.children.length !== 0 && (
                  <motion.div
                    key="animate-expand"
                    css={{overflow: 'hidden'}}
                    animate={{height: 'auto'}}
                    initial={{height: 0}}
                    exit={{height: 0}}
                    transition={{
                      ease: 'easeOut',
                      duration: 0.3,
                    }}
                    className="sub equals"
                  >
                    <CompetencesList courses={course.children} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        )}
        {size === 'employee' && (
          <div className={`competence-card ${size}${isExpanded ? ' expanded' : ''}`}>
            <div
              className="grid-x clickable-card"
              role="button"
              tabIndex={0}
              onKeyPress={() => this.handleChildrenUpdate(course.competence_id)}
              onClick={() => this.handleChildrenUpdate(course.competence_id)}
            >
              <div className="cell auto">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}
                >
                  {canEdit && !isMobile && (
                    <div className="edit-button">
                      <ActionButton
                        variant="defaultTheme"
                        onClick={() => onEdit(course)}
                      >
                        {i18n('globals.edit')}
                      </ActionButton>
                    </div>
                  )}
                  {canEdit && isMobile && (
                    <div className="edit-button mobile">
                      <Pencil
                        color="#0b5dd7"
                        role="button"
                        tabIndex="0"
                        onKeyUp={evt => evt.key === 'Enter' && onEdit(course)}
                        onClick={() => onEdit(course)}
                      />
                    </div>
                  )}
                  <div className="roles-list__competence-title">
                    <div className="roles-list__competence-status">
                      {course.passed === 100
                        && (
                          <img
                            src={completed}
                            alt="all-ok"
                            style={{width: 20}}
                          />
                        )
                       || course.passed !== 0 && (
                         <div
                           style={{
                             width:24,
                             height: 24,
                             backgroundColor: '#fafafa',
                             padding: 4,
                             justifyContent: 'center',
                             alignItems: 'center',
                             display: 'flex',
                             fontSize: 9,
                             borderRadius: 24,
                             boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.12)',
                           }}
                         >
                           <CircularProgressbar
                             value={course.passed}
                             strokeWidth={50}
                             styles={buildStyles({
                               strokeLinecap: 'butt',
                               backgroundColor: 'red',
                               pathColor: theme.chartPrimaryColor,
                               trailColor: theme.chartSecondaryColor,
                             })}
                           />
                         </div>
                       )}
                    </div>

                    <div className="info-wrapper">
                      <div className="small-header">
                        {i18nCourseGetCompetenceType(course)}
                      </div>
                      {course.competence_title}
                      <div className="signed-info">
                        {course.verified && course.verified.date && (
                          <div className="signed-date">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            &nbsp;&nbsp;
                            {course.verified.date}
                          </div>
                        )}
                        {course.verified && course.verified.fullname && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon={faUserCheck} />
                            &nbsp;&nbsp;
                            {course.verified.fullname}
                          </div>
                        )}
                      </div>
                    </div>

                    {course.files && course.files.length > 0 && (
                      <FilesList
                        className="files-list"
                        files={course.files.map(({title, url}) => ({
                          title,
                          url,
                        }))}
                        wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                      />
                    )}
                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>

              <div className="cell shrink button">
                {!isExpanded  && <FontAwesomeIcon icon={sub ? faChevronRight : faChevronDown} /> || (
                  course.children
                    && course.children.length === 0
                    && isFetching && <span>...</span>
                    || <FontAwesomeIcon icon={faChevronUp} />
                )}
              </div>
            </div>

            <div className="small-12 cell">
              <AnimatePresence>
                {isExpanded && course.children && course.children.length !== 0 && (
                  <motion.div
                    key="animate-expand"
                    css={{overflow: 'hidden'}}
                    animate={{height: 'auto'}}
                    initial={{height: 0}}
                    exit={{height: 0}}
                    transition={{
                      ease: 'easeOut',
                      duration: 0.3,
                    }}
                    className="sub equals"
                  >
                    <CompetencesList
                      canEdit={canEdit}
                      onEdit={onEdit}
                      size={size}
                      courses={course.children}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        )}
        {size === 'big' && (
          <>
            <div className="cell small-4 medium-5 course-image-wrapper">
              <div
                className="img"
                style={{
                  backgroundImage: `url(${course.cover && course.cover.url
                    || defaultImg})`,
                }}
              />
            </div>
            <div className="content cell small-8 medium-7 course-content-wrapper">
              <div className="__title">
                <h2>{course.competence_title}</h2>
              </div>
              {course.competence_type.competence_type
                && (
                  <div>
                    {i18nCourseGetCompetenceType(course)}
                  </div>
                )}
              <div
                className="desc"
                dangerouslySetInnerHTML={createSubtitleContent(course.short_description)}
              />
              {course.files && course.files.length > 0 && !isExpanded && (
                <FilesList
                  className="files-list"
                  files={course.files.map(({title, url}) => ({
                    title,
                    url,
                  }))}
                  wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                />
              )}

              <div
                className="button"
                role="button"
                tabIndex={0}
                onKeyPress={() =>
                  this.handleChildrenUpdate(course.competence_id)}
                onClick={() => this.handleChildrenUpdate(course.competence_id)}
              >
                {!isExpanded && !noExpandChildren && (
                  <>
                    <br />
                    {i18n('globals.view-all')}
                    <FontAwesomeIcon icon={sub ? faChevronRight :  faChevronDown} />
                  </>
                )
                  || course.children && (
                    course.children.length === 0 && isFetching
                      && <span>...</span>
                      || (
                        <>
                          <br />
                          {i18n('globals.hide')}
                          <FontAwesomeIcon icon={faChevronUp} />
                        </>
                      )
                  )}
              </div>
              <div className="small-12 cell">
                <AnimatePresence>
                  {isExpanded && course.children && course.children.length !== 0 && (
                    <motion.div
                      key="animate-expand"
                      css={{overflow: 'hidden'}}
                      animate={{height: 'auto'}}
                      initial={{height: 0}}
                      exit={{height: 0}}
                      transition={{
                        ease: 'easeOut',
                        duration: 0.3,
                      }}
                      className="sub equals"
                    >
                      <CompetencesList
                        courses={course.children}
                        size="children"
                        onToggleCompetence={profileToggleCompetence}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

CompetenceEquivalents.defaultProps = {
  roleName: '',
  size: 'normal',
  root: 'std',
  roleId: -2,
  removeRequirement: undefined,
};

CompetenceEquivalents.propTypes = {
  course: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  roleName: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  size: PropTypes.string,
  root: PropTypes.string,
  removeRequirement: PropTypes.func,
  roleId: PropTypes.number,
  fetchCompetencesChildren: PropTypes.func.isRequired,
  profileToggleCompetence: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCompetencesChildren,
      profileToggleCompetence,
    },
    dispatch,
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetenceEquivalents));
