/* eslint-disable react/jsx-fragments */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {i18n} from '@src/i18n';
import SearchBar from '../search-bar/search-bar';

import {connect, useSelector} from 'react-redux';
import {
  getProfileId,
  getOrganisationId,
  getMessageUnreadCount,
  getProfile,
} from '@selectors/profile.selectors';
import {getRouteWithParams} from '@utils/routes.utils';
import {css} from '@emotion/react';
import {
  courseCatalog,
  employees as employeeRoutes,
  myEducation,
} from '@routes/routes.manifest';
import {withRouter, matchPath} from 'react-router';
import {
  getIsMobile,
  getIsMobileCourseCatalog,
} from '@selectors/global.selectors';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import {getSelectedCompetencegroupId} from '@selectors/courses.selectors';
import MobileMenu from './mobile-menu/mobile-menu';
import DesktopMenu from './desktop-menu/desktop-menu';

import {rotor} from './rotor';
import {appTitle} from '@config';
import {MenuTwo} from '@components/menu/menu-two/MenuTwo';
import {useHistory} from 'react-router-dom';

export const getColorConfigForGroup = ({
  courseGroupId,
  pathname,
  isMobile,
  configObject,
  forMenu,
}) => {
  const defaultColor
    = configObject.getProperty(`params.menu.${isMobile ? 'baseColorMobile' : 'baseColorDesktop'}`) || 'rgb(1, 0, 49)';

  if (forMenu) {
    return {
      color: defaultColor,
      createGradient: false,
    };
  }


  if (!isMobile) {
    return {
      color: configObject.getProperty('params.menu.baseColorDesktop'),
      createGradient: false,
    };
  }
  const noActiveCourseGroup
    = isMobile && pathname === '/course-catalog'
    || !isMobile && !courseGroupId
    || pathname === '/atlas';

  if (noActiveCourseGroup) {
    return {
      color: defaultColor,
      createGradient: false,
    };
  }

  return {
    color:
      configObject.getProperty(`params.menu.configByCourseGroupId.${courseGroupId}.baseColor`) || defaultColor,
    createGradient:
      configObject.getProperty(`params.menu.configByCourseGroupId.${courseGroupId}.gradient`) || false,
  };
};

const Menu = ({
  onLogoutClick,
  isManager,
  isMobile,
  orgId,
  personId,
  profileData,
  messagesCount,
  location,
}) => {
  const [isMobileMenuOpened, setMobileMenuOpened] = useState(false);

  const configObject = useSelector(getConfigObject);
  const menuConfig = configObject.getProperty('params.menu') || {};
  const selectedCourseGroupId = useSelector(getSelectedCompetencegroupId);
  const isMobileCourseCatalog = useSelector(getIsMobileCourseCatalog);
  const history = useHistory();

  const toggleSidebarMenu = () => {
    setMobileMenuOpened(!isMobileMenuOpened);
  };

  const messagesCountString = messagesCount && ` (${messagesCount})` || '';
  const baseTitle
    = configObject.getProperty('params.default-title-key')
    && i18n(configObject.getProperty('params.default-title-key'));

  const showDashboard  = configObject.isModuleEnabled('reports');

  const menuLinksManager = [
    {
      name: i18n('menu.my-page'),
      title: i18n('menu.my-page'),
      label: i18n('menu.my-page'),
      onClick: () => {
        history.push(getRouteWithParams(myEducation.main.path, {}))
      },
      type: 'featureLink',
      attach: ['mobile'],
      path: '/my-education',
      active: matchPath(location.pathname, {
        path: '/my-education',
        exact: false,
      }),
    },
    {
      name: i18n('menu.course-catalog'),
      label: i18n('menu.course-catalog'),
      title: `${i18n('menu.course-catalog')}${
        baseTitle && ` - ${baseTitle}` || ''
      }`,
      onClick: () => {
        history.push(getRouteWithParams(courseCatalog.main.path, {}))
      },
      path: '/course-catalog',
      type: 'featureLink',
      attach: ['mobile'],
      active: matchPath(location.pathname, {
        path: '/course-catalog',
        exact: false,
      }),
    },
    {
      name: i18n('menu.employees'),
      label: i18n('menu.employees'),
      title: i18n('menu.employees'),
      type: 'featureLink',
      attach: ['mobile'],
      onClick: () => {
        history.push(getRouteWithParams(employeeRoutes.employees, {orgId}))
      },
      path: getRouteWithParams(employeeRoutes.employees, {orgId}),
      active: matchPath(location.pathname, {
        path: '/employees',
        exact: false,
      }),
    },
  ];

  if (showDashboard) {
    menuLinksManager.push({
      name: i18n('menu.dashboard'),
      label: i18n('menu.dashboard'),
      title: i18n('menu.dashboard'),
      type: 'featureLink',
      attach: ['mobile'],
      onClick: () => {
        history.push(getRouteWithParams(employeeRoutes.reportSearch, {orgId}))
      },
      path: getRouteWithParams(employeeRoutes.reportSearch, {orgId}),
      active: matchPath(location.pathname, {
        path: '/dashboard/competence-report',
        exact: false,
      }),
    });
  }

  const menuLinksEmployee = [
    {
      name: i18n('menu.my-page'),
      title: i18n('menu.my-page'),
      label: i18n('menu.my-page'),
      onClick: () => {
        history.push(getRouteWithParams(myEducation.main.path, {}))
      },
      type: 'featureLink',
      attach: ['mobile'],
      path: '/my-education',
      active: matchPath(location.pathname, {
        path: '/my-education',
        exact: false,
      }),
    },
    {
      name: i18n('menu.course-catalog'),
      label: i18n('menu.course-catalog'),
      title: `${i18n('menu.course-catalog')}${
        baseTitle && ` - ${baseTitle}` || ''
      }`,
      onClick: () => {
        history.push(getRouteWithParams(courseCatalog.main.path, {}))
      },
      path: '/course-catalog',
      type: 'featureLink',
      attach: ['mobile'],
      active: matchPath(location.pathname, {
        path: '/course-catalog',
        exact: false,
      }),
    },
  ];

  const useMap = configObject.isMapActivated;
  const menuLinks = useMap ? null : isManager ? menuLinksManager : menuLinksEmployee;

  const setDocumentTitle = links =>
    links
      && links.map(({title, active, children}) => {
        if (active && title) {
          document.title = title + (appTitle ? ` - ${appTitle}` : '');
        }

        return setDocumentTitle(children);
      })
    ;

  setDocumentTitle(menuLinksManager);

  const colorConfigForGroup = getColorConfigForGroup({
    courseGroupId: selectedCourseGroupId,
    pathname: location.pathname,
    isMobile: isMobileCourseCatalog,
    configObject,
    forMenu: true,
  });

  return (
    <div
      css={css`
        display: contents;
      `}
    >
      {menuConfig && menuConfig.type === 'menu-two' && (
        <MenuTwo
          onLogoutClick={onLogoutClick}
          menuConfig={menuConfig}
          variant={isMobile ? 'hamburger' : 'horizontal'}
          createGradient={colorConfigForGroup.createGradient}
          baseColor={colorConfigForGroup.color}
        />
      ) || menuConfig && menuConfig.type === 'menu-default' && useMap && (
        <DesktopMenu
          orgId={orgId}
          history={history}
          isMobile={isMobile}
          data={{
            config: {
              align: 'center',
              width: 1500,
              height: '70px',
              primaryColor: menuConfig.color,
              dropdownWidth: 800,
              dropdownAlign: 'center',
            },
            buttons: [{
              label: i18n('menu.logout'),
              onClick: onLogoutClick,
              icon: 'fas door-open',
              type: 'simpleButton',
            }],
            logo: {src: menuConfig.logo},
            items: (menuConfig.access && menuConfig.access >= 2
              ? [{
                label: i18n('menu.admin'),
                type: 'menu',
                items: [
                  {
                    label: i18n('menu.administration'),
                    type: 'title',
                  },
                  {
                    type: 'separator',
                    margin: ['0', '0', '20px', '0'],
                  },
                  {
                    label: i18n('menu.maps'),
                    subLabel: i18n('menu.my-maps-text'),
                    onClick: (() => window.location = '/admin/maps'),
                    type: 'featureLink',
                  },
                  {
                    label: i18n('menu.resource-pages'),
                    subLabel: i18n('menu.resource-pages-text'),
                    onClick: (() => window.location = '/admin/resources'),
                    type: 'featureLink',
                  },
                  {
                    label: i18n('menu.persons'),
                    subLabel: i18n('menu.persons-text'),
                    onClick: (() => window.location = '/admin/persons'),
                    type: 'featureLink',
                  },
                ],
              }
              ]: null),
          }}
          menuLinks={menuLinks}
          onLogoutClick={onLogoutClick}
        />
      ) || (
        <DesktopMenu
          orgId={orgId}
          history={history}
          isMobile={isMobile}
          data={!profileData
            ? {
              config:{
                primaryColor: "#f0f0f0"
              }
            }
            : {
              config: {
                height: '70px',
                primaryColor: menuConfig.color
              },
              search: (isManager ? {
                placeholder: i18n('globals.search'),
                search: (searchTerm, context) => {
                  return {
                    result: <SearchBar
                        term={searchTerm}
                        showMenu={context.closeMenu}
                      />
                    }
                },
              } : null),
              logo: {src: menuConfig.logo, href: '/'},
              items: (isMobile ? menuLinks : null),
              profile: {
                displayName: profileData.fullname,
                items: [
                  {
                    label: i18n('person.menu.competences'),
                    subLabel: i18n('person.menu.competencesTxt'),
                    onClick: () => {
                     history.push(getRouteWithParams(myEducation.cvView.path, {personId: profileData.id}))
                    },
                    type: 'featureLink',
                  },
                  {
                    label: i18n('person.menu.profile'),
                    subLabel: i18n('person.menu.profileTxt'),
                    onClick: () => {
                      history.push(getRouteWithParams(myEducation.profileView.path, {personId: profileData.id}))
                    },
                    type: 'featureLink',
                  },
                  {
                    label: i18n('person.menu.messages'),
                    subLabel: i18n('person.menu.messagesTxt'),
                    onClick: () => {
                      history.push(getRouteWithParams(myEducation.messagesView.path, {personId: profileData.id}))
                    },
                    type: 'featureLink',
                  },
                  {type: 'separator'},
                  {
                    label: i18n('person.menu.logout'),
                    subLabel: i18n('person.menu.logoutTxt'),
                    onClick: () => {
                      history.push('/logout')
                    },
                    type: 'featureLink',
                  },
                ],
              },
            }}
          menuLinks={menuLinks}
          onLogoutClick={onLogoutClick}
        />
      )}
    </div>
  );
};

Menu.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  profileData: PropTypes.shape({}),
  orgId: PropTypes.number.isRequired,
  personId: PropTypes.number.isRequired,
  messagesCount: PropTypes.number.isRequired,
  location: PropTypes.shape({pathname: PropTypes.string.isRequired}).isRequired,
};

Menu.defaultProps = {profileData: null};

const mapStateToProps = state => ({
  orgId: getOrganisationId(state),
  personId: getProfileId(state),
  messagesCount: getMessageUnreadCount(state),
  isMobile: getIsMobile(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
